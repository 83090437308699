<template>
	<div class="edit-form-container">
		<div class="mcol-xs-auto nav-tabs-container section-row">
			<div class="main-group">
				<el-button
					v-for="tab in tabsList"
					:key="`tab_${tab.title}`"
					:class="['capitalize inverted', { active: tab.prop == activeTab }]"
					round
					type="primary"
					@click="toggleTab(tab)"
					v-text="tab.title"
				/>
			</div>
		</div>

		<!-- :validate="" -->
		<el-form
			ref="itemForm"
			class="item-edit-form section-row form-type-2 product-form"
			:model="formData"
			:rules="rules"
		>
			<div
				v-show="activeTab == tabsList[0].prop"
				:key="tabsList[0].title"
				class="tab-container"
			>
				<div class="formRow mrow flex wrap">
					<div class="mcol-xs-12 mcol-sm-6">
						<el-form-item label="Код товара" prop="code">
							<el-input v-model="formData.code" />
						</el-form-item>
					</div>

					<div class="mcol-xs-12 mcol-sm-6">
						<el-form-item label="Алиас товара" prop="alias">
							<el-input v-model="formData.alias" />
						</el-form-item>
					</div>
				</div>

				<div class="formRow mrow flex wrap">
					<div class="mcol-xs-12 mcol-sm-6">
						<el-form-item label="Название товара (ru)" prop="title_ru">
							<el-input v-model="formData.title_ru" />
						</el-form-item>
					</div>

					<div class="mcol-xs-12 mcol-sm-6">
						<el-form-item label="Назва товару (uk)" prop="title_uk">
							<el-input v-model="formData.title_uk" />
						</el-form-item>
					</div>
				</div>

				<div class="formRow mrow flex wrap">
					<div class="mcol-xs-12 mcol-sm-6">
						<el-form-item label="Описание товара (ru)" prop="description_ru">
							<editor
								v-model="formData.description_ru"
								:api-key="editorAPIKey"
								:init="{
									toolbar: 'numlist bullist',
									selector: 'textarea',
									plugins: 'lists link',
									language: 'ru'
								}"
								placeholder="Введите описание"
							>
							</editor>
						</el-form-item>
					</div>

					<div class="mcol-xs-12 mcol-sm-6">
						<el-form-item label="Опис товару (uk)" prop="description_uk">
							<editor
								v-model="formData.description_uk"
								:api-key="editorAPIKey"
								:init="{
									toolbar: 'numlist bullist',
									selector: 'textarea',
									plugins: 'lists link',
									language: 'ru'
								}"
								placeholder="Введіть опис"
							>
							</editor>
						</el-form-item>
					</div>
				</div>

				<div class="formRow mrow flex wrap">
					<div class="mcol-xs-12 mcol-sm-6 relative">
						<SimpleSpinner :active="categoriesLoading" />
						<el-form-item label="Категория товара" prop="category_id">
								<!-- @change="changeCategory" -->
							<el-select
								v-model="formData.category_id"
								placeholder="выберите категорию"
								:disabed="!categoriesList.length"
								popper-class="product-category"
							>
								<el-option
									v-for="item in categoriesList"
									:key="'category_id-' + item.id"
									:class="[
										{ 'has-children': item.count_children },
										{ 'has-parent': item.parent_id }
									]"
									:disabled="!!item.count_children"
									:label="item.title_ru"
									:value="item.id"
								/>
							</el-select>
						</el-form-item>
					</div>
				</div>

				<div class="formRow mrow flex wrap">
					<div class="mcol-xs-6 mcol-sm-3">
						<el-form-item label="Цена товара" prop="price">
							<el-input-number
								v-model="formData.price"
								placeholder="Цена"
								:controls="false"
							/>
								<!-- :precision="2" -->
						</el-form-item>
					</div>
				</div>

				<div class="formRow mrow">
					<div class="mcol-xs-12 mcol-sm-6 relative">
						<el-form-item label="Дисконт" prop="discount_id">
							<SimpleSpinner :active="discountsLoading" />
							<el-select
								v-model="formData.discount_id"
								clearable
								placeholder="выберите дисконт"
								:disabled="!discountsList.length"
							>
								<el-option
									v-for="item in discountsList"
									:key="'discount_id-' + item.id"
									:label="item.title"
									:value="item.id"
								/>
							</el-select>
						</el-form-item>
						<!-- <el-form-item label="Дисконт">
							<el-select
								v-model="discount_type_id"
								placeholder="выберите тип дисконта"
								clearable
								@change="changeDiscountType"
							>
								<el-option
									v-for="item in discountTypesList"
									:key="'discount_type_id-' + item.id"
									:label="item.title_ru"
									:value="item.id"
								/>
							</el-select>
						</el-form-item> -->
					</div>
				</div>

				<div class="formRow mrow">
					<div class="mcol-xs-12 mcol-sm-6 relative">
						<el-form-item label="Тэги" prop="tags_ids">
							<SimpleSpinner :active="tagsLoading" />
							<el-select
								v-model="formData.tags_ids"
								clearable multiple
								placeholder="выберите тэги"
								:disabled="!tagsList.length"
							>
								<el-option
									v-for="item in tagsList"
									:key="'tags_ids-' + item.id"
									:label="item.title_ru"
									:value="item.id"
								/>
							</el-select>
						</el-form-item>
						<!-- <div class="mcol-xs-6 mcol-sm-3 1customCheckboxContainer">
							<el-form-item label="Хит" prop="is_hit">
								<el-switch 
									v-model="formData.is_hit"
									:active-value="1"
									:inactive-value="0"
								/>
							</el-form-item>
						</div>
						<div class="mcol-xs-6 mcol-sm-3 1customCheckboxContainer">
							<el-form-item label="Новинка" prop="is_new">
								<el-switch v-model="formData.is_new"
									:active-value="1"
									:inactive-value="0"
								/>
							</el-form-item>
						</div> -->
						<!-- <div class="mcol-xs-3 mcol-sm-1">
							<el-form-item label="Тэги" prop="action_state">
								<el-checkbox v-model="acknowledgeOnly">Хит</el-checkbox>
							</el-form-item>
						</div> -->
					</div>
				</div>

				<div class="formRow mrow flex wrap">
					<div class="mcol-xs-6 mcol-sm-3 ">
						<el-form-item label="Товар активен" prop="is_published">
							<el-switch
								v-model="formData.is_published"
								:active-value="1"
								:inactive-value="0"
							/>
						</el-form-item>
					</div>
				</div>
			</div>

			<div
				v-show="activeTab == tabsList[1].prop"
				:key="tabsList[1].title"
				class="tab-container product-attributes-tab"
			>
				<div v-if="formData.category_id" class="relative">
					<SimpleSpinner :active="attributeGroupsLoading" />

					<div v-if="attributeGroupsList.length" class="">
						<div
							v-for="group in attributeGroupsList"
							:key="`attr_group-${group.id}`"
							class="attribute-group-item"
						>
							<div class="title section-title">{{ group.title_ru }}</div>

							<div v-show="group.attributes.length" class="attributes-list">
								<AttributeListItem
									v-for="attribute in group.attributes"
									ref="AttributeListItem"
									:key="`attribute-${attribute.id}`"
									:item-data="attribute"
									:options-ids="attributeOptionsIds"
								/>

								<!-- <div
									class=""
									v-for="attribute in group.attributes"
									:key="`attribute-${attribute.id}`"
								>
									<div class="title article-title" v-text="attribute.title_ru"></div>
								</div> -->
							</div>
							<div v-show="!group.attributes.length" class="text-center">
								Эта группа не содержит атрибутов
							</div>
						</div>
					</div>
					<div v-else class="text-center">
						Выбранная категория товаров не содержит атрибутов
					</div>
				</div>
				<div v-else class="text-center">Укажите категорию товара</div>
			</div>

			<div
				v-show="activeTab == tabsList[2].prop"
				:key="tabsList[2].title"
				class="tab-container"
			>
				<div class="formRow mrow flex wrap">
					<div class="mcol-xs-12 mcol-sm-6">
						<el-form-item label="SEO Название товара (ru)" prop="seo_title_ru">
							<el-input v-model="formData.seo_title_ru" />
						</el-form-item>
					</div>

					<div class="mcol-xs-12 mcol-sm-6">
						<el-form-item label="SEO Назва товара (uk)" prop="seo_title_uk">
							<el-input v-model="formData.seo_title_uk" />
						</el-form-item>
					</div>
				</div>

				<div class="formRow mrow flex wrap">
					<div class="mcol-xs-12 mcol-sm-6">
						<el-form-item label="SEO описание товара (ru)" prop="seo_description_ru">
							<el-input
								v-model="formData.seo_description_ru"
								type="textarea"
								rows="4"
							/>
						</el-form-item>
					</div>

					<div class="mcol-xs-12 mcol-sm-6">
						<el-form-item label="SEO опис товара (uk)" prop="seo_description_uk">
							<el-input
								v-model="formData.seo_description_uk"
								type="textarea"
								rows="4"
							/>
						</el-form-item>
					</div>
				</div>
			</div>

			<div
				v-show="activeTab == tabsList[3].prop"
				:key="tabsList[3].title"
				class="tab-container"
			>
				<div class="formRow relative">
					<div class="title section-title">Основное изображение</div>
					<ImgUploadBlock
						ref="ImgUploadBlock"
						:picture="pictures.main"
						:replace-selected-file="true"
						uid="mainImgUploadBlock"
						:main-file="true"
						@onInit="uploadBlockInit"
					/>
				</div>
				<div class="formRow relative">
					<div class="title section-title">Изображения для галереи</div>

					<ImgUploadBlock
						ref="ImgUploadBlock"
						:pictures="pictures.gallery"
						multiple
						uid="galleryUploadBlock"
						@onInit="uploadBlockInit"
					/>
				</div>
			</div>

			<!-- <el-form-item label="Cookie hash">
				<div class="el-input">{{ formData.cookie_hash }}</div>
			</el-form-item> -->

			<!-- <el-form-item label="Статус заказа" prop="status">
				<el-select placeholder="выберите статус" v-model="formData.status">
					<el-option v-for="item in orderStatusesList" :key="'status-' + item.id" :label="item.name" :value="item.id" />
				</el-select>
			</el-form-item> -->

			<!-- <el-form-item label="Комментарий" prop="comment">
				<el-input type="textarea" v-model="formData.comment" rows="4" />
			</el-form-item> -->
		</el-form>
	</div>
</template>

<script>
import 'element-ui/lib/theme-chalk/upload.css';
import { mapActions, mapState } from 'vuex';

import { required } from '@/constants/validation';
import { editorAPIKey, product_actions } from '@/constants/global';
import {
	itemFormMixin,
	itemsFetchSetMixin,
	tabsMixin
	// setupDiscountMixin
} from '@/mixins';
import { getValues /* , updateFormData */ } from '@/helpers';

export default {
	components: {
		Editor: () => import('@tinymce/tinymce-vue'),
		ImgUploadBlock: () => import('../../components/form/ImgUploadBlock.vue'),
		AttributeListItem: () => import('./AttributeListItem.vue')
	},
	mixins: [itemFormMixin, itemsFetchSetMixin, tabsMixin],
	props: {
		itemData: {
			type: Object,
			default: () => ({})
		},
		fromAnotherInstance: Boolean
	},

	data() {
		return {
			uploadBlocksList: [],

			formData: {
				alias: '',
				code: '',
				title_uk: '',
				title_ru: '',
				description_uk: '',
				description_ru: '',
				price: '',
				discount_id: null,
				category_id: null,
				// brand_id: null,
				action_state: null,
				seo_title_uk: '',
				seo_title_ru: '',
				seo_description_uk: '',
				seo_description_ru: '',
				is_published: 0,

				// is_hit: 0,
				// is_new: 0
				// attribute_options_id: []
				// pictures_id: [],
			},

			attributesFormData: {},
			attributeOptionsIds: []
		};
	},

	computed: {
		...mapState({
			categoriesLoading: state => state.categories.isLoading,
			categoriesList: state => state.categories.itemsList,
			attributeGroupsLoading: state => state.attribute_groups.isLoading,
			attributeGroupsList: state => state.attribute_groups.itemsList,
			discountsList: state => state.discounts.itemsList,
			discountsLoading: state => state.discounts.isLoading,
			tagsList: state => state.tags.itemsList,
			tagsLoading: state => state.tags.isLoading
		}),
		// idPropName: () => 'alias',
		product_actions: () => product_actions,
		categoriesWithoutChildrenOnlyList() {
			return this.$store.getters['categories/categoriesWithoutChildrenOnlyList'];
		},

		rules: () => ({
			title_uk: required,
			title_ru: required,
			// seo_title_uk: required,
			// seo_title_ru: required,
			// description_uk: required,
			// description_ru: required,
			// seo_description_uk: required,
			// seo_description_ru: required,
			price: required,
			category_id: required
		}),

		editorAPIKey: () => editorAPIKey,

		tabsList: () => [
			{ title: 'Информация', prop: 'descriptionTabActive' },
			{ title: 'Атрибуты', prop: 'attributesTabActive' },
			{ title: 'SEO', prop: 'seoTabActive' },
			{ title: 'Изображения', prop: 'imgTabActive' }
		],

		pictures() {
			let main = null;
			let gallery = [];

			const { pictures } = this.itemData ? this.itemData : {};

			if (pictures && pictures.length) {
				for (const pic of pictures) {
					if (pic.is_main) {
						main = pic.picture_file_name;
					} else {
						gallery.push(pic);
					}
				}
			}

			return {
				main: main,
				gallery: gallery
			};
		},

		translitSettings: () => ({
			formFields: [{ prop: 'alias', sourceProp: 'title_ru', ifIsEmpty: true }]
		}),

		initialSetFetchSettings: () => [
			{
				action: 'fetch_categories',
				payload: { params: { max: -1, withChildren: false } },
				clean_up: 'set_categories'
			},
			{
				action: 'fetch_attribute_groups',
				payload: { params: { max: -1 } },
				bindTo: { prop: 'formData.category_id', param: 'categoryId' },
				clean_up: 'set_attribute_groups'
			},
			{
				action: 'fetch_discounts',
				payload: { params: { max: -1 } },
				clean_up: 'set_discounts'
			},
			{
				action: 'fetch_tags',
				payload: { params: { max: -1 } },
				clean_up: 'set_tags'
			}
		]
	},

	methods: {
		...mapActions({
			fetch_categories: 'categories/fetch_categories',
			set_categories: 'categories/set_categories',
			fetch_attribute_groups: 'attribute_groups/fetch_attribute_groups',
			set_attribute_groups: 'attribute_groups/set_attribute_groups',
			fetch_discounts: 'discounts/fetch_discounts',
			set_discounts: 'discounts/set_discounts',
			fetch_tags: 'tags/fetch_tags',
			set_tags: 'tags/set_tags'

			// fetch_warehouses: 'nova_poshta/fetch_warehouses',
			// set_cities: 'nova_poshta/set_cities',
			// set_warehouses: 'nova_poshta/set_warehouses'
		}),

		/*fetchCities() {
			this.fetch_cities({ params: { max: 10 } });
		},*/

		/*setupPage(item) {
			if (item) {
				this.itemId = item.id;
				this.formData = this.setupForm(item, this.formData);
				
			}
		},*/

		/* setupForm(itemData, formData) {
			const attribute_options_id = getValues('id', itemData.attributeOptions);
			console.log(attribute_options_id);
			return updateFormData(itemData, formData, {
				// attribute_options_id: attribute_options_id
			});
		}, */
		/*localSetupPage(item) {
			if (item && item.is_published) {
				this.formData.is_published = 1;
			}
		},*/

		uploadBlockInit(data) {
			this.uploadBlocksList.push(data);
		},

		changeDiscountType() {
			this.formData.discount_id = null;
		},

		/*changeCategory(id) {
			// this.formData
			this.set_attribute_groups([]);

			if (id) {
				this.fetch_attribute_groups({
					params: { categoryId: id }
				});
			}
		},*/

		getSubFormsData({ refName, prop }) {
			// переделать/отрефакторить
			const refs = this.$refs[refName];
			let data = [];

			if (refs) {
				if (refs instanceof Array) {
					for (const ref of refs) {
						const value = ref._data[prop];
						if (value instanceof Array) {
							data.push(...value);
						} else if (value || typeof value == 'boolean') {
							data.push(value);
						}
					}
				}
			}

			return data;
		},

		validateForm() {
			this.$refs['itemForm'].validate(valid => {
				if (valid) {
					// переделать/отрефакторить

					const attribute_options_id = this.getSubFormsData({
						refName: 'AttributeListItem',
						prop: 'option_id'
					});

					if (attribute_options_id.length) {
						this.formData.attribute_options_id = attribute_options_id;
					}

					this.submitForm();
				} else {
					this.$notify({
						type: 'warning',
						title: 'Форма не готова',
						message: `Проверте все ли обязательные поля заполненены`
					});
					return false;
				}
			});
		}
	},

	created() {
		this.attributeOptionsIds = this.itemData
			? getValues('id', this.itemData.attributeOptions)
			: [];
	},
};
</script>
